<template>
	<b-card no-body class="mb-1 transparent">
		<v-toolbar elevation="0" max-height="40" class="mb-6 transparent">
			<div class="w-75">
				<v-tabs v-model="tab" height="50" background-color="transparent">
					<v-tab>
						{{ $t("settings.lang_receiptSetFooter") }}
					</v-tab>
					<v-tab>
						{{ $t("generic.lang_homePage") }}
					</v-tab>
					<v-tab>
						{{ $t("generic.lang_invoiceLayout") }}
					</v-tab>
					<v-tab v-if="$store.getters['permissions/checkModule'](67)">
						Kiosk
					</v-tab>
				</v-tabs>
			</div>
			<v-spacer />
			<v-btn depressed color="success" class="ma-0" @click="update" :loading="loadingLayout">
				{{ $t("generic.lang_save") }}
			</v-btn>
		</v-toolbar>

		<v-tabs-items v-model="tab">
			<v-tab-item>
				<b-tabs>
					<b-tab active>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="DE" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Datenschutz-Bestimmungen</strong></v-subheader>
									<quill-editor id="privacy_editor_9" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.german.privacyPolicy">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="GB" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Privacy Policy</strong></v-subheader>
									<quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.english.privacyPolicy">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="FR" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Politique de confidentialité</strong> </v-subheader>
									<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.french.privacyPolicy">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="SA" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>سياسةالخصوصية</strong></v-subheader>
									<quill-editor id="privacy_editor_1" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.arabic.privacyPolicy">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>
				</b-tabs>
				<v-divider class="ma-0" />
				<br /><br />
				<b-tabs>
					<b-tab active>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="DE" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Geschäftsbedingungen</strong></v-subheader>
									<quill-editor id="privacy_editor_7" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.german.termsAndConditions">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="GB" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Terms and Conditions</strong></v-subheader>
									<quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.english.termsAndConditions">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="FR" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Termes et conditions</strong> </v-subheader>
									<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.french.termsAndConditions">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="SA" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>الأحكام والشروط</strong></v-subheader>
									<quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.arabic.termsAndConditions">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>
				</b-tabs>
				<v-divider class="ma-0" />
				<br /><br />
				<b-tabs>
					<b-tab active>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="DE" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Impressum</strong></v-subheader>
									<quill-editor id="privacy_editor_5" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.german.imprints">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="GB" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Imprints</strong></v-subheader>
									<quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.english.imprints">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="FR" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>Empreintes</strong></v-subheader>
									<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.french.imprints">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="SA" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>ختم الناشر</strong></v-subheader>
									<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="dynamicContent.arabic.imprints">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>
				</b-tabs>
			</v-tab-item>
			<v-tab-item>
				<b-tabs>
					<b-tab active>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="DE" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_title") }}</strong></v-subheader>
									<quill-editor id="privacy_editor_8" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.german.title">
									</quill-editor>
								</v-col>
								<v-col class="" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_description") }}</strong></v-subheader>
									<quill-editor id="privacy_editor" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.german.description">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="GB" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_title") }}</strong></v-subheader>
									<quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.english.title">
									</quill-editor>
								</v-col>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_description") }}</strong></v-subheader>
									<quill-editor id="privacy_editor_2" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.english.description">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="FR" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_title") }}</strong>
									</v-subheader>
									<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.french.title">
									</quill-editor>
								</v-col>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_description") }}</strong>
									</v-subheader>
									<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.french.description">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="SA" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_title") }}</strong></v-subheader>
									<quill-editor id="privacy_editor_3" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.arabic.title">
									</quill-editor>
								</v-col>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{ $t("generic.lang_description") }}</strong></v-subheader>
									<quill-editor id="privacy_editor_4" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="homePage.arabic.description">
									</quill-editor>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>
				</b-tabs>
			</v-tab-item>
			<v-tab-item>
				<b-tabs>
					<b-tab active>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="DE" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-textarea rows="4" outlined dense :label="$t('generic.lang_openingTimes')"
										v-model="invoiceLayout.german.openingTimes" />
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="GB" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-textarea rows="4" outlined dense :label="$t('generic.lang_openingTimes')"
										v-model="invoiceLayout.french.openingTimes" />
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="FR" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-textarea rows="4" outlined dense :label="$t('generic.lang_openingTimes')"
										v-model="invoiceLayout.english.openingTimes" />
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="SA" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-textarea rows="4" outlined dense :label="$t('generic.lang_openingTimes')"
										v-model="invoiceLayout.arabic.openingTimes" />
								</v-col>
							</v-row>
						</b-card>
					</b-tab>
				</b-tabs>
			</v-tab-item>
			<v-tab-item v-if="$store.getters['permissions/checkModule'](67)">
				<b-tabs>
					<b-tab active>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="DE" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{$t('generic.lang_content',"de")}}</strong></v-subheader>
									<quill-editor id="privacy_editor_9" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="kioskPage.german.content">
									</quill-editor>
								</v-col>
								<v-col cols="12">
									<v-select outlined :label="$t('generic.lang_alertColor','de')" dense item-value="name"
										item-text="name" :color="kioskPage.german.alert.color" :items="colors" chips
										clearable v-model="kioskPage.german.alert.color">
										<template v-slot:selection="{ item }">
											<v-chip dark :color="item.name" small>
											</v-chip>
											{{ item.name }}
										</template>
										<template v-slot:item="{ item, attrs, on }">
											<v-list-item v-on="on" v-bind="attrs">
												<v-list-item-content>
													<v-list-item-title>
														<v-row no-gutters align="center">
															<v-chip :color="item.name" small></v-chip>
															<span class="ml-2">{{ item.name }}</span>
														</v-row>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
									</v-select>
									<v-textarea :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:label="$t('generic.lang_alertBody')" outlined @focus="showTouchKeyboard"
										rows="7" hide-details dense v-model="kioskPage.german.alert.body">
										<template v-slot:append>
											<v-switch v-model="kioskPage.german.alert.status" dense hide-details inset
												class="ma-0 mr-n4 pa-0">
											</v-switch>
										</template>
									</v-textarea>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="GB" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{$t('generic.lang_content',"en")}}</strong></v-subheader>
									<quill-editor id="privacy_editor_9" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="kioskPage.english.content">
									</quill-editor>
								</v-col>
								<v-col cols="12">
									<v-select outlined :label="$t('generic.lang_alertColor','en')" dense item-value="name"
										item-text="name" :color="kioskPage.english.alert.color" :items="colors" chips
										clearable v-model="kioskPage.english.alert.color">
										<template v-slot:selection="{ item }">
											<v-chip dark :color="item.name" small>
											</v-chip>
											{{ item.name }}
										</template>
										<template v-slot:item="{ item, attrs, on }">
											<v-list-item v-on="on" v-bind="attrs">
												<v-list-item-content>
													<v-list-item-title>
														<v-row no-gutters align="center">
															<v-chip :color="item.name" small></v-chip>
															<span class="ml-2">{{ item.name }}</span>
														</v-row>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
									</v-select>
									<v-textarea :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:label="$t('generic.lang_alertBody')" outlined @focus="showTouchKeyboard"
										rows="7" hide-details dense v-model="kioskPage.english.alert.body">
										<template v-slot:append>
											<v-switch v-model="kioskPage.english.alert.status" dense hide-details inset
												class="ma-0 mr-n4 pa-0">
											</v-switch>
										</template>
									</v-textarea>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="FR" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{$t('generic.lang_content',"fr")}}</strong></v-subheader>
									<quill-editor id="privacy_editor_9" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="kioskPage.french.content">
									</quill-editor>
								</v-col>
								<v-col cols="12">
									<v-select outlined :label="$t('generic.lang_alertColor','fr')" dense item-value="name"
										item-text="name" :color="kioskPage.french.alert.color" :items="colors" chips
										clearable v-model="kioskPage.french.alert.color">
										<template v-slot:selection="{ item }">
											<v-chip dark :color="item.name" small>
											</v-chip>
											{{ item.name }}
										</template>
										<template v-slot:item="{ item, attrs, on }">
											<v-list-item v-on="on" v-bind="attrs">
												<v-list-item-content>
													<v-list-item-title>
														<v-row no-gutters align="center">
															<v-chip :color="item.name" small></v-chip>
															<span class="ml-2">{{ item.name }}</span>
														</v-row>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
									</v-select>
									<v-textarea :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:label="$t('generic.lang_alertBody')" outlined @focus="showTouchKeyboard"
										rows="7" hide-details dense v-model="kioskPage.french.alert.body">
										<template v-slot:append>
											<v-switch v-model="kioskPage.french.alert.status" dense hide-details inset
												class="ma-0 mr-n4 pa-0">
											</v-switch>
										</template>
									</v-textarea>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>

					<b-tab>
						<template #title>
							<v-btn text class="ma-0">
								<country-flag country="SA" />
							</v-btn>
						</template>
						<b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'pa-2']">
							<v-row no-gutters>
								<v-col class="pb-6" cols="12">
									<v-subheader><strong>{{$t('generic.lang_content',"ar")}}</strong></v-subheader>
									<quill-editor id="privacy_editor_9" :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:options="quillOptions" @focus="showTouchKeyboard" output="html"
										class="mb-quill" v-model="kioskPage.arabic.content">
									</quill-editor>
								</v-col>
								<v-col cols="12">
									<v-select outlined :label="$t('generic.lang_alertColor','ar')" dense item-value="name"
										item-text="name" :color="kioskPage.arabic.alert.color" :items="colors" chips
										clearable v-model="kioskPage.arabic.alert.color">
										<template v-slot:selection="{ item }">
											<v-chip dark :color="item.name" small>
											</v-chip>
											{{ item.name }}
										</template>
										<template v-slot:item="{ item, attrs, on }">
											<v-list-item v-on="on" v-bind="attrs">
												<v-list-item-content>
													<v-list-item-title>
														<v-row no-gutters align="center">
															<v-chip :color="item.name" small></v-chip>
															<span class="ml-2">{{ item.name }}</span>
														</v-row>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
									</v-select>
									<v-textarea :data-layout="KEYBOARD.KEYSETS.NORMAL"
										:label="$t('generic.lang_alertBody')" outlined @focus="showTouchKeyboard"
										rows="7" hide-details dense v-model="kioskPage.arabic.alert.body">
										<template v-slot:append>
											<v-switch v-model="kioskPage.arabic.alert.status" dense hide-details inset
												class="ma-0 mr-n4 pa-0">
											</v-switch>
										</template>
									</v-textarea>
								</v-col>
							</v-row>
						</b-card>
					</b-tab>
				</b-tabs>
			</v-tab-item>
		</v-tabs-items>
		<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
			<vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
				:defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input" :layout="touchKeyboard.layout"
				:options="touchKeyboard.options" class="internalWidth" id="onScreenKeyboard"
				v-if="touchKeyboard.visible" />
		</div>
	</b-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import CountryFlag from "vue-country-flag";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { createNamespacedHelpers } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import Quill from "quill";
import QuillResize from "quill-resize-module";

Quill.register("modules/resize", QuillResize);

const FontAttributor = Quill.import("attributors/class/font");
let SizeStyle = Quill.import("attributors/style/size");
FontAttributor.whitelist = ["Poppins", "Roboto", "Nunito", "Dancingscript"];
SizeStyle.whitelist = [
	"8px",
	"10px",
	"12px",
	"14px",
	"16px",
	"18px",
	"20px",
	"24px",
	"28px",
	"32px",
	"36px",
	"40px",
	"48px",
	"56px",
	"64px",
	"72px",
	"80px",
	"96px",
	"128px",
];
Quill.register(FontAttributor, true);
Quill.register(SizeStyle, true);

// Fix : Dynamicaly changing font style for each font item
FontAttributor.whitelist.forEach((font) => {
	const style = document.createElement("style");
	style.innerHTML = `.ql-font-${font} { font-family: ${font}; }`;
	document.head.appendChild(style);
});

export default {
	name: "PrivacyAGBConditionsComponent",
	mixins: [mixin],
	components: {
		CountryFlag,
		quillEditor,
	},
	data() {
		return {
			loadingLayout: false,
			tab: 0,
			colors: [
				{ name: 'primary' },
				{ name: 'purple' },
				{ name: 'success' },
				{ name: 'error' },
				{ name: 'warning' },
			],
			invoiceLayout: {
				arabic: {
					openingTimes:
						"Mo: 15 - 19 Uhr\n" + "        Di - Fr: 10 - 19 Uhr\n" + "        Sa - So: 10 - 14 Uhr",
				},
				french: {
					openingTimes:
						"Mo: 15 - 19 Uhr\n" + "        Di - Fr: 10 - 19 Uhr\n" + "        Sa - So: 10 - 14 Uhr",
				},
				english: {
					openingTimes:
						"Mo: 15 - 19 Uhr\n" + "        Di - Fr: 10 - 19 Uhr\n" + "        Sa - So: 10 - 14 Uhr",
				},
				german: {
					openingTimes:
						"Mo: 15 - 19 Uhr\n" + "        Di - Fr: 10 - 19 Uhr\n" + "        Sa - So: 10 - 14 Uhr",
				},
			},
			homePage: {
				arabic: {
					title: "",
					description: "",
				},
				french: {
					title: "",
					description: "",
				},
				english: {
					title: "",
					description: "",
				},
				german: {
					title: "",
					description: "",
				},
			},
			dynamicContent: {
				arabic: {
					agb: "",
					imprints: "",
					privacyPolicy: "",
					termsAndConditions: "",
				},
				english: {
					agb: "",
					imprints: "",
					privacyPolicy: "",
					termsAndConditions: "",
				},
				french: {
					agb: "",
					imprints: "",
					privacyPolicy: "",
					termsAndConditions: "",
				},
				german: {
					agb: "",
					imprints: "",
					privacyPolicy: "",
					termsAndConditions: "",
				},
			},
			kioskPage: {
				arabic: {
					content: null,
					alert: {
						body: null,
						color: null,
						status: false,
					}
				},
				english: {
					content: null,
					alert: {
						body: null,
						color: null,
						status: false,
					}
				},
				french: {
					content: null,
					alert: {
						body: null,
						color: null,
						status: false,
					}
				},
				german: {
					content: null,
					alert: {
						body: null,
						color: null,
						status: false,
					}
				},
			},

			quillOptions: {
				// register the image resize module

				modules: {
					resize: {
						modules: ["Resize", "DisplaySize", "Toolbar"],
					},
					toolbar: [
						// show image picker
						[{ color: [] }],
						["image", "video", "link", "clean"],
						["bold", "italic", "underline", "strike"],
						[{ size: SizeStyle.whitelist }],
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						[{ align: [] }],
						[{ font: FontAttributor.whitelist }],
						[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
					],
				},
			},
		};
	},
	computed: {
		...createNamespacedHelpers("settings").mapGetters(["getSettingValue"]),
		isTranslationEnabled() {
			return parseInt(this.getSettingValue("enable_translation")) === 1;
		},
	},
	methods: {
		updateKioskContent() {
			if(!this.$store.getters['permissions/checkModule'](67))
				return;
			
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.UPDATEKIOSKCONTENT, {
					content_de: this.kioskPage.german.content,
					content_en: this.kioskPage.english.content,
					content_fr: this.kioskPage.french.content,
					content_ar: this.kioskPage.arabic.content,
					alert_de: this.kioskPage.german.alert,
					alert_en: this.kioskPage.english.alert,
					alert_fr: this.kioskPage.french.alert,
					alert_ar: this.kioskPage.arabic.alert
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_actionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		updateInvoiceLayout() {
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.UPDATEINVOICELAYOUT, {
					eb_ticket_openingHours_de: this.invoiceLayout.german.openingTimes,
					eb_ticket_openingHours_en: this.invoiceLayout.english.openingTimes,
					eb_ticket_openingHours_fr: this.invoiceLayout.french.openingTimes,
					eb_ticket_openingHours_ar: this.invoiceLayout.arabic.openingTimes,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_actionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		updateHomePageContent() {
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.UPDATEHOME, {
					title_de: this.homePage.german.title,
					description_de: this.homePage.german.description,
					title_en: this.homePage.english.title,
					description_en: this.homePage.english.description,
					title_fr: this.homePage.french.title,
					description_fr: this.homePage.french.description,
					title_ar: this.homePage.arabic.title,
					description_ar: this.homePage.arabic.description,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_actionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		updateFooterContent() {
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.UPDATEFOOTER, {
					privacyPolicy_ar: this.dynamicContent.arabic.privacyPolicy,
					agb_ar: this.dynamicContent.arabic.agb,
					termsAndConditions_ar: this.dynamicContent.arabic.termsAndConditions,
					imprints_ar: this.dynamicContent.arabic.imprints,
					privacyPolicy_fr: this.dynamicContent.french.privacyPolicy,
					agb_fr: this.dynamicContent.french.agb,
					termsAndConditions_fr: this.dynamicContent.french.termsAndConditions,
					imprints_fr: this.dynamicContent.french.imprints,
					privacyPolicy_en: this.dynamicContent.english.privacyPolicy,
					agb_en: this.dynamicContent.english.agb,
					termsAndConditions_en: this.dynamicContent.english.termsAndConditions,
					imprints_en: this.dynamicContent.english.imprints,
					privacyPolicy_de: this.dynamicContent.german.privacyPolicy,
					agb_de: this.dynamicContent.german.agb,
					termsAndConditions_de: this.dynamicContent.german.termsAndConditions,
					imprints_de: this.dynamicContent.german.imprints,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_actionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		update() {
			if (this.tab === 0) {
				this.updateFooterContent();
			} else if (this.tab === 1) {
				this.updateHomePageContent();
			} else if (this.tab === 2) {
				this.updateInvoiceLayout();
			} else {
				this.updateKioskContent();
			}
		},
		getInvoiceLayout() {
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.GETINVOICELAYOUT)
				.then((res) => {
					if (res.status === 200) {
						if (res.data.hasOwnProperty("german")) {
							Object.assign(this.invoiceLayout, res.data);
						}
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		getKioskContent() {
			if(!this.$store.getters['permissions/checkModule'](67))
				return;
			
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.GETKIOSKCONTENT)
				.then((res) => {
					if (res.status === 200) {
						if (res.data.hasOwnProperty("german")) {
							Object.assign(this.kioskPage, res.data);
							for (let locale in this.kioskPage) {
								if (this.kioskPage[locale].alert === 0) {
									this.kioskPage[locale].alert = {
										body: null,
										color: null,
										status: false,
									}
								}
							}
						}
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		getFooterContent() {
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.GETFOOTER)
				.then((res) => {
					if (res.status === 200) {
						Object.assign(this.dynamicContent, res.data);
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
		getHomePageContent() {
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.DYNAMICCONTENT.GETHOME)
				.then((res) => {
					if (res.status === 200) {
						Object.assign(this.homePage, res.data);
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				});
		},
	},
	mounted() {
		this.getFooterContent();
		this.getHomePageContent();
		this.getInvoiceLayout();
		this.getKioskContent();
	},
};
</script>

<style scoped>
#privacy_editor_4,
#privacy_editor_1,
#privacy_editor_2,
#privacy_editor_3,
#privacy_editor_5,
#privacy_editor_7,
#privacy_editor_8,
#privacy_editor_9 {
	height: 300px !important;
}
</style>
<style>
.mb-quill {
	margin-bottom: 50px !important;
}

/*
.ql-snow .ql-editor h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	letter-spacing: 0;
}

.ql-snow .ql-editor h1 {
	font-size: 4em;
}
.ql-snow .ql-editor h2 {
	font-size: 3em;
}
.ql-snow .ql-editor h3 {
	font-size: 2.34em;
}

.ql-snow .ql-editor * {
	color: black;
}
*/
</style>
